import React, {useEffect} from "react";
import sanitizeHtml from 'sanitize-html';
import Img from "gatsby-image";
import sanitizeOptions from "../../helper/sanitizeOptions";
import sanitizeAddCustomLink from "../../helper/sanitizeAddCustomLink";

const RecruitersListItem = ({startAnimation, el}) => {
    const sanitizeOpt = sanitizeOptions();

    useEffect(()=>{
            sanitizeAddCustomLink();
    }, []);

    return (
        <li>
            {!!el?.photo?.remoteFile?.childImageSharp && (
                <div
                    style={{
                        overflow: 'hidden'
                    }}
                >
                    <Img
                        style={{
                            transform:`translateX(${startAnimation? 0: '-100%'})`,
                            transition: 'all .75s ease .5s'
                        }}
                        fluid={
                            el.photo.remoteFile.childImageSharp.fluid
                        }
                    />
                </div>
            )}
            {!!el.name && (
                <div className={'careers__recruiters-name h4-title'}>
                    {el.name}
                </div>
            )}
            {!!el.position && (
                <div className={'careers__recruiters-position'}>
                    {el.position}
                    <div className={'border-bottom'}
                         style={{
                             width:`${startAnimation? '100%' :0}`,
                             transition: 'all .75s ease .5s'
                         }}
                    />
                </div>
            )}
            {!!el.contacts && (
                <div
                    className={'text'}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(el.contacts, sanitizeOpt.title) }}
                    style={{
                        opacity:`${startAnimation? 1 :0}`,
                        transition: 'all .75s ease .7s'
                    }}
                />
            )}
        </li>
    )
}

export default RecruitersListItem
