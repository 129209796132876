import React, {useEffect} from "react"
import sanitizeHtml from 'sanitize-html';
import TitleLargeContent from "./content"
import sanitizeOptions from "../../helper/sanitizeOptions";
import useTitleAnimation from "../../hooks/useTitleAnimation";
import addVideoLink from "../../helper/addVideoLink";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";

const TitleLarge = ({data, contactUsData, isFrontPage}) => {
    const sanitizeOpt = sanitizeOptions();
    const isHtmlProcessed = useTitleAnimation();
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < constants.MOBILE_WIDTH;

    useEffect(() => {
        //To add the src attribute for video tag in admin content
        addVideoLink(constants.SITE_BASE_URL);
    }, []);

    return (
        <div className={'title-large-content'}
             style={{opacity: isMobile ? 1 : isHtmlProcessed ? 1 : 0}}>
            <div className={'content-holder'}>
                {!!data?.title && (
                    <div
                        className={'title-large'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(data.title, sanitizeOpt.contentData)}}
                    />
                )}
                {!!data?.mainDescription && !isFrontPage && (
                    <div
                        className={'main-description body-font-styles'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(data.mainDescription, sanitizeOpt.contentData)}}
                    />
                )}
            </div>

            {/*Home page*/}
            {!!data?.mainDescription && isFrontPage && (
                <div
                    className={'main-description body-font-styles'}
                    dangerouslySetInnerHTML={{__html: sanitizeHtml(data.mainDescription, sanitizeOpt.contentData)}}
                />
            )}

            <TitleLargeContent
                data={data}
                contactUsData={contactUsData}/>


            {!!data?.additionalText && (
                <div
                    className={'additional-text body-font-styles'}
                    dangerouslySetInnerHTML={{__html: sanitizeHtml(data.additionalText, sanitizeOpt.contentData)}}
                />
            )}

        </div>
    )
}


export default TitleLarge