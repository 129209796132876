import React, {useEffect, useState} from "react";
import CareersListItem from './CareersListItem'
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const sortOrder = (a, b) => (a.vacancyCustomFields.sortOrder - b.vacancyCustomFields.sortOrder);

const CareersList = ({isVisible, data}) => {
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <div className={'careers__vacancy-block'}>
            {!!data?.weAreLookingFor?.title && (
                <div className={'h2-title'}>
                    <div
                        style={{
                            transform:`translateY(${startAnimation? 0: '100%'})`,
                            transition: 'all .75s ease .5s'
                        }}
                    >
                        {data.weAreLookingFor.title}
                    </div>
                </div>
            )}
            <ul className={'careers__list flex-col'}>
                {!!data?.vacancy?.nodes && data.vacancy.nodes.sort(sortOrder).map((el, index) => (
                    <CareersListItem
                        key={index}
                        el={el}
                        index={index}
                        startAnimation={startAnimation}
                    />
                ))}
            </ul>
        </div>
    )
}

export default CareersList