import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";

const CareersListItem = ({startAnimation, el, index}) => {
    const delay = 100 + index * 110;

    return (
        <li className={'careers-list__item'}
            key={el.id}>
            {(index < 1) && (
                <div
                    className={'separator-line top'}
                    style={{
                        width:`${startAnimation? '100%': 0}`,
                        transition: `all .75s ease ${delay + 300}ms`
                    }}
                />
            )}
            <div
                className={'separator-line bottom'}
                style={{
                    width:`${startAnimation? '100%': 0}`,
                    transition: `all .75s ease ${delay + 300}ms`
                }}
            />
            <AniLink to={normalizePath(el.uri)}
                     paintDrip
                     duration={1}
                     hex="#ed5d2b"
                     className={'careers-list__link'}
                     style={{
                         opacity:`${startAnimation? 1: 0}`,
                         transition: `all .75s ease ${delay}ms`
                     }}
            >
                <span className="h5-title minor-title black">
                    {el.vacancyCustomFields.careersLocation}
                </span>
                <h4 className={'h4-title major-title black'}
                    data-animated-group="content">
                    {el.title}
                </h4>
            </AniLink>
        </li>
    )
}

export default CareersListItem