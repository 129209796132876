import React, {useEffect, useState} from "react";
import RecruitersListItem from './RecruitersListItem';
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const RecruitersList = ({isVisible, data}) => {
    const {title, recruitersList} = data;
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <div className={'careers__recruiters-block'}>
            {!!title && (
                <h2 className={'h2-title'}>
                    {title}
                </h2>
            )}
            {!!recruitersList && (
                <ul className={'careers__recruiters-items'}>
                    {recruitersList.map((el, index) => (
                        <RecruitersListItem
                            key={index}
                            el={el}
                            startAnimation={startAnimation}
                        />
                    ))}
                </ul>
            )}
        </div>
    )
}

export default RecruitersList
