import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from "gatsby";
import Layout from '../components/layout';
import TitleLarge from "../components/title-large-content";
import WhoWeAre from "../components/careers-who-we-are";
import OurEvents from "../components/careers-our-events";
import CareersList from "../components/careers-list";
import WhatWeOffer from "../components/careers-what-we-offer";
import RecruitersList from "../components/recruiters-list";
import scrollToBlock from "../helper/scrollToBlock";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import TrackVisibility from 'react-on-screen';

export default () => {
    const data = useStaticQuery(graphql`
        {
            careers: wpPage(slug: {eq: "careers"}) {
                title
                careerCustomFields {
                    careerTitleLargeContent {
                        button
                        title
                    }
                    whoWeAre {
                        amountBlock {
                            fieldGroupName
                            title
                            text
                        }
                        descriptionBlock {
                            title
                            text
                        }
                        socialLinks
                    }
                    ourEvents {
                        title
                        text
                        button {
                            text
                            link
                        }
                        image {
                            link
                            imageSource {
                                remoteFile {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                    whatWeOffer {
                        title
                        whatWeOfferItems {
                            fieldGroupName
                            text
                            image {
                                remoteFile {
                                     childImageSharp {
                                         fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            position
                        }
                    }
                    weAreLookingFor {
                        title
                    }
                    recruiters {
                        title
                        recruitersList {
                            fieldGroupName
                            photo {
                                remoteFile {
                                     childImageSharp {
                                         fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            name
                            position
                            contacts
                        }
                    }
                    careersContactUsBlock {
                        description
                        text
                        titleH2
                        titleH4
                        classWrapper
                    }
                }
                featuredImage {
                    node {
                        sourceUrl
                        remoteFile {
                        childImageSharp {
                                fluid(maxWidth: 1496, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                         }
                    }
                }
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage {
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }
            
            vacancy: allWpVacancy (
            sort: { fields: date, order: DESC }
            ) {
                nodes {
                    id
                    title
                    uri
                    vacancyCustomFields {
                        careersLocation
                        sortOrder
                    }
                }
            }
        }
    `)

    const {careers, vacancy} = data;
    const {title, careerCustomFields, featuredImage, seo, additionalMetaTag} = careers;
    const {
        careerTitleLargeContent,
        whoWeAre,
        ourEvents,
        whatWeOffer,
        weAreLookingFor,
        recruiters,
        careersContactUsBlock,
    } = careerCustomFields;
    const dataContactBlock = careersContactUsBlock;
    const socialLinks = whoWeAre?.socialLinks;
    const careersList = 'careers-list';
    const [animateMainImage, setAnimateMainImage] = useState(false);
    const sendCvBlock = 'send-cv-block';
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    useEffect(() => {
        let topSendCV = document.getElementById('send-cv');

        function scrollToCV() {
            scrollToBlock(`.${sendCvBlock}-wrapper`);
        }

        topSendCV.addEventListener('click', scrollToCV);

        window.setTimeout(() => {
            setAnimateMainImage(true)
        }, 1000);
    }, []);

    return (
        <Layout
            seoData={seoData}>
            <div className={'careers inner-pages'}
                 datafld={{dataContactBlock}}>
                <Helmet
                    bodyAttributes={{
                        class: 'vacancy-main-page-wrapper'
                    }}
                />
                <div className={'careers__title-block'}>
                    <TitleLarge
                        data={careerTitleLargeContent}/>
                    {!!careerTitleLargeContent.button && (
                        <button
                            onClick={() => {
                                scrollToBlock(`.${careersList}-wrapper`)
                            }}
                            className={'transition-link mc-link mc-button-secondary bottom'}>
                        <span className={'label'}>
                            {careerTitleLargeContent.button}
                        </span>
                        </button>
                    )}
                </div>
                <LazyLoad offsetTop={100}>
                    <div className={'careers__main-image-block'}>
                        {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                            <Img
                                fluid={
                                    featuredImage.node.remoteFile.childImageSharp.fluid
                                }
                                style={{
                                    // transform: `${animateMainImage ? '' : 'rotate(20deg)'}`,
                                    opacity: `${animateMainImage ? 1 : 0}`,
                                    transition: 'all .75s ease'
                                }}
                            />
                        )}
                    </div>
                </LazyLoad>
                <TrackVisibility once offset={250}>
                    <WhoWeAre data={{whoWeAre, socialLinks}}/>
                </TrackVisibility>
                <TrackVisibility once offset={350}>
                    <OurEvents data={ourEvents}/>
                </TrackVisibility>
                <WhatWeOffer data={whatWeOffer}/>
                <TrackVisibility
                    once
                    offset={500}
                    className={'careers-list-wrapper'}>
                <CareersList data={{vacancy, weAreLookingFor}}/>
                </TrackVisibility>
                <TrackVisibility once offset={600}>
                    <RecruitersList data={recruiters}/>
                </TrackVisibility>
                {!!sendCvBlock && (
                    <LazyLoad offsetTop={50} classNamePrefix={!!sendCvBlock && sendCvBlock}>
                        <div id={sendCvBlock}/>
                    </LazyLoad>
                )}
            </div>
        </Layout>
    )
}
