import React, {useState, useEffect} from "react"
import sanitizeHtml from "sanitize-html";
import ReactModal from "react-modal";
import MageButton from "../mageButton";
import ContactBlock from "../contact-block";
import sanitizeOptions from "../../helper/sanitizeOptions";
import * as constants from "../../helper/constants";
import Img from "gatsby-image";

const ContentHomePage = ({data, contactUsData}) => {
    const sanitizeOpt = sanitizeOptions();
    const [open, setOpen] = useState(false);
    const [showTagline, setShowTagline] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [windowSize, setWindowSize] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalStyles = {
        overlay: {
            backgroundColor: '#EC5C2B'
        },
    };

    const {tagline, socialLinks, button, link, backgroundColor, textColor, buttonSize} = data;

    useEffect(() => {
        let getWindowSize = window.innerWidth < constants.MOBILE_WIDTH;
        setWindowSize(getWindowSize);
        setShowTagline(!getWindowSize);
        setShowLinks(!getWindowSize);
    }, []);

    return (
        <div className={'home-content-container-inner top-content image-box flex-justify-sb'}>
            {!!data?.image && (
                <div className={'image-holder'}>
                    <Img className={'image'}
                         fluid={data.image.remoteFile.childImageSharp.fluid}/>
                </div>
            )}
            {!!tagline && (
                <div className={'tagline-container'}>
                        <span
                            style={{
                                opacity: windowSize ? 1 : showTagline ? 1 : 0,
                                transition: !windowSize ? 'all .75s ease 1.2s': ''
                            }}
                            className={'tagline h5-title visible animated'}
                            dangerouslySetInnerHTML={{__html: sanitizeHtml(tagline, sanitizeOpt.title)}}/>
                </div>
            )}
            <div
                className={'links-container flex-al__c-jc__fe'}
                style={{
                    opacity: windowSize ? 1 : showLinks ? 1 : 0,
                    transition: !windowSize ? 'all .75s ease 1.2s' : ''
                }}
            >
                {!!socialLinks && (
                    <div className={'media-links-container'}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(socialLinks, sanitizeOpt.title)}}/>
                )}
                {!!contactUsData?.contactUsBlock && !!button && (
                    <div className={'homepage-blob-container'}
                         id={'homepage-blob-container'}
                    >
                        <MageButton
                            clickEvent={handleOpen}
                            color={`#EC5C2B`}
                            textColor={textColor}
                            size={!!buttonSize ? +buttonSize : undefined}
                            text={button}/>
                        <ReactModal
                            isOpen={open}
                            onRequestClose={handleClose}
                            className={'modal-content orange-background'}
                            style={modalStyles}
                            ariaHideApp={false}
                            htmlOpenClassName={'get-free-popup__open'}>
                            <div className="page-content-modal page-content get-free-popup">
                                <button onClick={handleClose}
                                        className={'close-button'}
                                        aria-label='Close'
                                        type='submit'/>
                                <ContactBlock
                                    parentBlock={'get-in-touch'}
                                    contactBlock={contactUsData.contactUsBlock}/>
                            </div>
                        </ReactModal>
                    </div>
                )}

                {!!link?.url && !!link?.text && (
                    <MageButton
                        color={backgroundColor}
                        url={link.url}
                        text={link.text}
                        size={!!buttonSize ? +buttonSize : undefined}
                        textColor={textColor}
                    />
                )}
            </div>
        </div>
    )
}

export default ContentHomePage
